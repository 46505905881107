import axios from 'axios'

let baseAdminDomain = window.location.host.toString()
let baseDomain
switch (baseAdminDomain) {
  case 'admin-dev.hubswitch.com':
    baseDomain = 'https://inventory-api-dev.hubswitch.com'
    break
  case 'admin-beta.hubswitch.com':
    baseDomain = 'https://inventory-api-beta.hubswitch.com'
    break
  case 'admin.hubswitch.com':
    baseDomain = 'https://inventory-api.hubswitch.com'
    break
  case '127.0.0.1:3000':
    baseDomain = 'http://127.0.0.1:3030'
    break
  case 'admin-yok.hubswitch.com':
    baseDomain = 'https://inventory-api-beta.hubswitch.com'
    break
  case 'admin-ton.hubswitch.com':
    baseDomain = 'https://inventory-api-beta.hubswitch.com'
    break
  case 'admin-an.hubswitch.com':
    baseDomain = 'https://inventory-api-beta.hubswitch.com'
    break
  default:
    baseDomain = 'http://' + window.location.hostname + ':3030'
    break
}
const apiEndpoint = baseDomain + '/v1'

// Set up Axios instance
const apiInstance = axios.create({
  baseURL: apiEndpoint,
})

// Add a request interceptor
apiInstance.interceptors.request.use(
  (config) => {
    // The token is stored in local storage
    const token = localStorage.getItem('api_token')
    const invToken = localStorage.getItem('inv_token')
    if (config.url.includes('admins/token')) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      config.headers.Authorization = `Bearer ${invToken}`
    }
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

const api = {
  get: async (path, params = {}) => {
    try {
      const response = await apiInstance.get(`${apiEndpoint}/${path}`, {
        params,
      })
      return { data: response.data }
    } catch (error) {
      return {
        status: error.response?.status,
        error: error.response?.data?.error,
      }
    }
  },

  post: async (path, data) => {
    try {
      const response = await apiInstance.post(`${apiEndpoint}/${path}`, data)
      return { data: response.data }
    } catch (error) {
      return {
        status: error.response?.status,
        error: error.response?.data?.error,
      }
    }
  },

  put: async (path, data) => {
    try {
      const response = await apiInstance.put(`${apiEndpoint}/${path}`, data)
      return { data: response.data }
    } catch (error) {
      return {
        status: error.response?.status,
        error: error.response?.data?.error,
      }
    }
  },

  patch: async (path, data) => {
    try {
      const response = await apiInstance.patch(`${apiEndpoint}/${path}`, data)
      return { data: response.data }
    } catch (error) {
      return {
        status: error.response?.status,
        error: error.response?.data?.error,
      }
    }
  },

  delete: async (path) => {
    try {
      const response = await apiInstance.delete(`${apiEndpoint}/${path}`)
      return { data: response.data }
    } catch (error) {
      return {
        status: error.response?.status,
        error: error.response?.data?.error,
      }
    }
  },
}

export default api
