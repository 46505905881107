import React, {useState, useEffect, useRef} from 'react'
import api from '../../utils/Api'
import {Button, Form,  Modal} from 'react-bootstrap';

export default function OtpModal({show, onHide, mobile = '', submitText = 'บันทึกข้อมูล', onSubmit, header = 'ส่งคำขอยืนยันตัวตน'}) {
  const [pin, setPin] = useState('')
  const [sendingOtp, setSendingOtp] = useState(false)
  const [waiting, setWaiting] = useState(false)
  const checker = useRef()
  const clearChecker = () => {
    if (checker.current) {
      clearInterval(checker.current)
      checker.current = null
    }
  }

  const sendOtp = (cb = ()=>{}) => {
    setSendingOtp(true)
    api.request('otp/send', (r, s)=>{
      if (s) {
        cb()
      }
      setSendingOtp(false)
    }, api.form({mobile}), 'POST')
  }

  useEffect(() => {
    if (!show) {
      clearChecker()
      setWaiting(false)
    } else {
      sendOtp()
    }
  }, [show])

  return (
    <Modal show={show} onHide={onHide}>
      { !!header &&
        <Modal.Header className="bg-secondary">
          <Modal.Title >{header}</Modal.Title>
        </Modal.Header>
      }
      <Modal.Body>

      <Form.Group controlId="formBasicPassword" style={{flex: 2}} className="ml-3">
          <Form.Label>ระบบได้ส่ง OTP ไปหาท่านที่เบอร์{" "}<span className="text-primary">
          {mobile}</span></Form.Label>
          <Form.Control
              onChange={(e)=>{
                setPin(e.target.value)
              }}
              maxLength={6}
              placeholder={'กรอกรหัส OTP ที่ได้รับจาก SMS เพื่อยืนยันตัวตน'}
          />
          <a className={sendingOtp ? "text-muted" : "link"} onClick={() => {
            if (sendingOtp) return

            sendOtp()
          }}>{sendingOtp?'กำลังส่ง OTP..':'ไม่ได้รับ OTP? ส่งรหัสอีกครั้ง'}</a>
      </Form.Group>
      
      </Modal.Body>
      <Modal.Footer>

         <div className="d-flex">
         <Button disabled={waiting} size="sm" onClick={()=>{
             onHide()
         }} variant="muted">ยกเลิก</Button>
         <Button disabled={waiting} size="sm" onClick={()=>{
           setWaiting(true)
              api.request('otp/verify', (r, s) => { 
                if (s) {
                  onSubmit(r)
                  onHide()
                } else {
                  setWaiting(false)
                }
              }, api.form({mobile, pin}), 'POST')
         }} variant="secondary">ยืนยัน</Button>
         </div>

        
      </Modal.Footer>
    </Modal>
  )
}
